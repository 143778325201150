/**
 * Select users.
 *
 * @author Róbert Zentko
 * @version 1.0.0
 *
 * @param {Object} state - The module state.
 * @param {Object} getters - The module getters.
 * @return {Object}
 */
export const selectUserCourses = (state, getters) => userId => getters.selectUser(userId)?.courses_expiration
