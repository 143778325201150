import { api } from '../../api'
import { toast } from '@/util/toast'
import { getErrorMessage } from '@/util/getErrorMessage'

/**
 * Delete user action.
 *
 * @version 1.0.0
 *
 * @param {function} commit
 * @param {string} userId
 * @return {Promise}
 */
export const deleteUser = ({ commit }, userId) => {
    
    // hide toasts
    toast.clear()
    
    // dispatch request
    commit('delete.request')
    
    // api request
    return api.user.delete(userId)
        .then(() => {
            
            // dispatch success
            commit('delete.success', userId)
            
            // show toast
            toast.success('Používateľ vymazaný')
            
        })
        .catch(response => {
            
            // dispatch error
            commit('delete.failure')
            
            // show toast
            toast.danger(getErrorMessage(response))
            
        })
    
}
