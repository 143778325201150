/**
 * Get user mutation.
 *
 * @author Róbert Zentko
 * @version 1.0.0
 */
export const getUserMutation = {
    'get-user.request': state => {
        state.fetching = true
    },
    'get-user.success': (state, payload) => {
        state.fetching = false
        state.user = payload
    },
    'get-user.failure': state => {
        state.fetching = false
    },
}
