import { api } from '../../api'
import router from '../../../router'
import { toast } from '@/util/toast'
import { i18n } from '@/locale'

/**
 * Login action.
 *
 * @author Róbert Zentko
 * @version 1.0.0
 *
 * @param {function} commit
 * @param {Object} payload
 * @return {Promise}
 */
export const login = ({ commit }, payload) => {
    
    // hide all toast
    toast.clear()
    
    // dispatch login request
    commit('login.request')
    
    // api request
    return api.auth.login(payload)
              .then(response => {
                  
                  // dispatch login success
                  commit('login.success', response?.data?.data)
                  
                  // redirect to dashboard
                  router.push({ name: 'dashboard' }).then(() => {
                      i18n.locale = response?.data?.data?.language ?? 'sk'
                  })
                  
              })
              .catch(response => {
                  
                  // dispatch login error
                  commit('login.failure')
                  
                  // show toast
                  toast.danger(response?.response?.data?.message)
                  
              })
    
}
