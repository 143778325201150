/**
 * Reset password mutation.
 *
 * @author Róbert Zentko
 * @version 1.0.0
 */
export const resetPasswordMutation = {
    'reset-password.request': state => {
        state.fetching = true
    },
    'reset-password.success': state => {
        state.fetching = false
    },
    'reset-password.failure': state => {
        state.fetching = false
    }
}
