/**
 * Login mutation.
 *
 * @author Róbert Zentko
 * @version 1.0.0
 */
export const loginMutation = {
    'login.request': state => {
        state.fetching = true
    },
    'login.success': (state, payload) => {
        state.accessToken = payload?.access_token
        state.fetching = false
        state.user = payload?.user
    },
    'login.failure': state => {
        state.fetching = false
    }
}
