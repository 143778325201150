/**
 * Select the auth user error.
 *
 * @author Róbert Zentko
 * @version 1.0.0
 *
 * @param {Object} state - The module state.
 * @param {Object} getters - The module getters.
 * @return {Object}
 */
export const selectRole = (state, getters) => getters.selectUser?.role
