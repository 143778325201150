/**
 * Whether the request is fetching.
 *
 * @author Róbert Zentko
 * @version 1.0.0
 *
 * @param {Object} state - The module state.
 * @return {boolean}
 */
export const isFetching = state => state?.fetching
