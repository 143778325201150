import { find, propEq } from 'ramda'

/**
 * Select users.
 *
 * @author Róbert Zentko
 * @version 1.0.0
 *
 * @param {Object} state - The module state.
 * @param {Object} getters - The module getters.
 * @return {Object}
 */
export const selectUser = (state, getters) => userId => find(propEq('id', userId), getters.selectUsers)
