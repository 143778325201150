/**
 * Auth routes.
 */
export const authRoutes = [
    {
        path: '/login',
        name: 'login',
        component: () => import('../../screen/Login'),
        meta: {
            public: true,
            title: 'Prihlásenie'
        }
    },
    {
        path: '/reset-password',
        name: 'reset-password',
        component: () => import('../../screen/ResetPassword'),
        meta: {
            public: true,
            title: 'Aktivácia účtu'
        }
    },
    {
        path: '/forgot-password',
        name: 'forgot-password',
        component: () => import('../../screen/ForgotPassword'),
        meta: {
            public: true,
            title: 'Zabudnuté heslo'
        }
    },
    {
        path: '/auth/reset-password',
        redirect: { name: 'reset-password' }
    },
]