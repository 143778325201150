import { api } from '../../api'
import router from '../../../router'
import { toast } from '@/util/toast'
import { getValidationErrors } from '@/util/getValidationErrors'

/**
 * Store course action.
 *
 * @version 1.0.0
 *
 * @param {function} commit
 * @param {Object} payload
 * @return {Promise}
 */
export const storeCourse = ({ commit }, payload) => {
    
    // hide toasts
    toast.clear()
    
    // dispatch request
    commit('store.request')
    
    // api request
    return api.course.store(payload)
        .then(response => {
            
            // dispatch success
            commit('store.success', response?.data?.data)
            
            // show toast
            toast.success('Kurz vytvorený')
            
            // redirect
            router.push({ name: 'courses.index' }).then()
            
        })
        .catch(response => {
            
            // dispatch error
            commit('store.failure')
            
            // show toast
            toast.danger('Kurz sa nepodarilo vytvoriť', getValidationErrors(response))
            
        })
        .finally(() => {
            
            // reset upload progress
            commit('axios/setUploadProgress', 0, { root: true })
            
        })
    
}
