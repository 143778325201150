import { api } from '../../api'
import { toast } from '@/util/toast'

/**
 * Delete course action.
 *
 * @version 1.0.0
 *
 * @param {function} commit
 * @param {string} courseId
 * @return {Promise}
 */
export const deleteCourse = ({ commit }, courseId) => {
    
    // hide toasts
    toast.clear()
    
    // dispatch  request
    commit('delete.request')
    
    // api request
    return api.course.delete(courseId)
        .then(() => {
            
            // dispatch success
            commit('delete.success', courseId)
            
            // show toast
            toast.success('Kurz vymazaný')
            
        })
        .catch(() => {
            
            // dispatch error
            commit('delete.failure')
            
            // show toast
            toast.danger('Kurz sa nepodarilo vymazať')
            
        })
    
}
