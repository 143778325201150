import { map, prop, sortBy } from 'ramda'

/**
 * Update user mutation.
 *
 * @version 1.0.0
 */
export const updateUserMutation = {
    'update.request': state => {
        state.fetching = true
    },
    'update.success': (state, payload) => {
        state.data = sortBy(prop('last_name'), map(item => item.id === payload.id ? { ...item, ...payload } : item, state.data))
        state.fetching = false
    },
    'update.failure': state => {
        state.fetching = false
    },
}
