/**
 * Fetch courses mutation.
 *
 * @author Róbert Zentko
 * @version 1.0.0
 */
export const fetchCoursesMutation = {
    'fetch.request': state => {
        state.fetching = true
    },
    'fetch.success': (state, payload) => {
        state.data = payload?.data
        state.fetching = false
    },
    'fetch.failure': state => {
        state.data = []
        state.fetching = false
    }
}
