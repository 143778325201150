/**
 * Fetch users mutation.
 *
 * @author Róbert Zentko
 * @version 1.0.0
 */
export const fetchUsersMutation = {
    'fetch.request': state => {
        state.fetching = true
    },
    'fetch.success': (state, payload) => {
        state.data = payload?.data
        state.fetching = false
        state.pagination = {
            currentPage: 1,
            lastPage: payload?.meta?.last_page,
            perPage: payload?.meta?.per_page,
        }
    },
    'fetch.failure': state => {
        state.data = []
        state.fetching = false
    },
}
