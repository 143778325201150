import { prop, sortBy } from 'ramda'

/**
 * Store user mutation.
 *
 * @version 1.0.0
 */
export const storeUserMutation = {
    'store.request': state => {
        state.fetching = true
    },
    'store.success': (state, payload) => {
        state.data = sortBy(prop('last_name'), [...state.data, payload])
        state.fetching = false
    },
    'store.failure': state => {
        state.fetching = false
    },
}
