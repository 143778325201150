import Vue from 'vue'
import VueRouter from 'vue-router'
import { store } from '@/store'
import { userRoutes } from './route/user'
import { authRoutes } from './route/auth'
import { courseRoutes } from './route/course'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'dashboard',
        component: () => import('../screen/Dashboard'),
        meta: {
            title: 'Nástenka'
        }
    },
    ...authRoutes,
    ...courseRoutes,
    ...userRoutes
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach(((to, from, next) => {
    
    // only admin access
    if (to.meta?.admin && ! store.getters['auth/isAdmin']) {
        next(from)
        
        return
    }
    
    // redirect if authenticated
    if (to.path === '/login' && store.getters['auth/isAuthenticated']) {
        next({ name: 'dashboard' })
        
        return
    }
    
    // verify auth
    if (! to.meta?.public && ! store.getters['auth/isAuthenticated']) {
        next({ name: 'login' })
        
        return
    }
    
    // continue
    next()
    
}))

router.afterEach(to => Vue.nextTick(() => document.title = `Monika Majorošová | Inštruktorka psychomotorického vývoja dieťatka | ${to.meta?.title}`))

export default router
