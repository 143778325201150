/**
 * Fetch video link mutation.
 *
 * @author Róbert Zentko
 * @version 1.0.0
 */
export const setCurrentVideo = {
    'setCurrentVideo': (state, payload) => {
        state.video = payload
    },
}
