import { api } from '../../api'

/**
 * Fetch courses action.
 *
 * @version 1.0.0
 *
 * @param {function} commit
 * @return {Promise}
 */
export const fetchCourses = ({ commit }) => {
    
    // dispatch request
    commit('fetch.request')
    
    // api request
    return api.course.list()
        .then(response => {
            
            // dispatch success
            commit('fetch.success', response?.data)
            
        })
        .catch(() => {
            
            // dispatch error
            commit('fetch.failure')
            
        })
    
}
