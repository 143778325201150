import { api } from '../../api'
import { toast } from '@/util/toast'
import router from '@/router'
import { i18n } from '@/locale'

/**
 * Get user.
 *
 * @author Róbert Zentko
 * @version 1.0.0
 *
 * @param {function} commit
 * @param {function} dispatch
 * @return {Promise}
 */
export const getUser = ({ commit, dispatch }) => {
    
    // dispatch login request
    commit('get-user.request')
    
    // api request
    return api.auth.getUser()
              .then(response => {
                  const user = response?.data?.data
                  
                  if (user?.role?.id !== 'admin') {
                      i18n.locale = user?.language ?? 'sk'
                  }
                  
                  // If user is inactive, logout.
                  if (user?.status === 'inactive') {
                      api.auth.logout()
                         .finally(() => {
                             
                             // clear vuex
                             dispatch('clearAll', null, { root: true })
                             
                             // show toast
                             toast.danger(i18n.t('Konto bolo deaktivované'))
                             
                             // redirect to login
                             router.push({ name: 'login' })
                                   .then()
                             
                         })
                      
                      return
                  }
                  
                  // dispatch get-user success
                  commit('get-user.success', user)
                  
              })
              .catch(response => {
                  
                  // dispatch get-user error
                  commit('get-user.failure')
                  
                  // show toast
                  toast.danger(response?.response?.data?.message)
                  
              })
    
}
