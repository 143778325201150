import { api } from '../../api'
import router from '../../../router'
import { toast } from '@/util/toast'
import { i18n } from '@/locale'

/**
 * Logout action.
 *
 * @author Róbert Zentko
 * @version 1.0.0
 *
 * @param {function} dispatch
 * @return {Promise}
 */
export const logout = ({ dispatch }) => {
    
    // api request
    api.auth.logout().finally(() => {
        
        // clear vuex
        dispatch('clearAll', null, { root: true })
        
        // show toast
        toast.success(i18n.t('Odhlásenie úspešné'))
        
        // redirect to login
        router.push({ name: 'login' }).then()
        
    })
    
}
