import VueI18n from 'vue-i18n'
import en from '@/locale/en.json'
import sk from '@/locale/sk.json'
import es from '@/locale/es.json'
import it from '@/locale/it.json'
import fr from '@/locale/fr.json'
import Vue from 'vue'

export const availableLocales = ['en', 'es', 'fr', 'it', 'sk']

let locale = 'sk'

// set language from URL
const params = new URLSearchParams(window.location.search)
const lang = (params.get('lang') ?? '')?.trim()
                                       ?.toLowerCase()

if (availableLocales.includes(lang)) {
    locale = lang
}

Vue.use(VueI18n)

export const i18n = new VueI18n({
    fallbackLocale: 'sk',
    locale: locale,
    messages: {
        en,
        es,
        sk,
        it,
        fr,
    },
})
