/**
 * Whether the user is administrator.
 *
 * @author Róbert Zentko
 * @version 1.0.0
 *
 * @param {Object} state - The module state.
 * @return {boolean}
 */
export const isAdmin = state => state?.user?.role?.id === 'admin'
