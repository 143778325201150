import { flatten, map, pipe, pluck, prop } from 'ramda'

/**
 * Select course packages ID.
 *
 * @version 1.0.0
 *
 * @param {Object} state - The module state.
 * @param {Object} getters - The module getters.
 * @return {Array}
 */
export const selectCoursePackagesId = (state, getters) => pipe(map(prop('packages')), flatten, pluck('id'))(getters.selectCourses)
