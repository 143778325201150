import Vue from 'vue'

/**
 * Global config.
 */
const defaultConfig = {
    duration: 5000,
}

/**
 * Toast helper.
 */
export const toast = {
    success: (title, message = '', config = {}) => Vue.$toast.success(`<b>${title}</b><br>${message}`, { ...defaultConfig, ...config }),
    warning: (title, message = '', config = {}) => Vue.$toast.warning(`<b>${title}</b><br>${message}`, { ...defaultConfig, ...config }),
    danger: (title, message = '', config = { duration: 0 }) => Vue.$toast.error(`<b>${title}</b><br>${message}`, { ...defaultConfig, ...config }),
    clear: () => Vue.$toast.clear()
}