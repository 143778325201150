/**
 * Get axios upload progress.
 *
 * @author Róbert Zentko
 * @version 1.0.0
 *
 * @param {Object} state - The module state.
 * @return {number}
 */
export const getUploadProgress = state => parseInt(state?.progress)
