/**
 * Course routes.
 */
export const courseRoutes = [
    {
        path: '/courses',
        name: 'courses.index',
        component: () => import('../../screen/CourseList'),
        meta: {
            admin: true,
            title: 'Kurzy',
        },
    },
    {
        path: '/courses/create',
        name: 'courses.create',
        component: () => import('../../screen/CreateCourse'),
        meta: {
            admin: true,
            title: 'Vytvoriť kurz',
        },
    },
    {
        path: '/courses/:courseId',
        name: 'courses.detail',
        component: () => import('../../screen/CourseDetail'),
        meta: {
            title: 'Detail kurzu',
        },
    },
    {
        path: '/courses/update/:courseId',
        name: 'courses.update',
        component: () => import('../../screen/EditCourse'),
        meta: {
            admin: true,
            title: 'Upraviť kurz',
        },
    },
]