import router from '../../../router'
import { api } from '../../api'
import { toast } from '@/util/toast'
import { getValidationErrors } from '@/util/getValidationErrors'
import { i18n } from '@/locale'

/**
 * Forgot password action.
 *
 * @author Róbert Zentko
 * @version 1.0.0
 *
 * @param {function} commit
 * @param {Object} payload
 * @param {Object} params
 * @return {Promise}
 */
export const forgotPassword = ({ commit }, payload) => {
    
    // hide toasts
    toast.clear()
    
    // dispatch request
    commit('forgot-password.request')
    
    // api request
    return api.auth.forgotPassword(payload)
        .then(() => {
            
            // dispatch success
            commit('forgot-password.success')
            
            // show toast
            toast.success(i18n.t('Obnovenie hesla'), i18n.t('Skontrolujte si e-mailovú schránku'))
            
            // redirect to dashboard
            router.push({ name: 'login' }).then()
            
        })
        .catch(response => {
            
            // dispatch error
            commit('forgot-password.failure')
            
            // show toast
            toast.danger(i18n.t('Obnovenie hesla'), getValidationErrors(response))
            
        })
    
}
