/**
 * Forgot password mutation.
 *
 * @author Róbert Zentko
 * @version 1.0.0
 */
export const forgotPasswordMutation = {
    'forgot-password.request': state => {
        state.fetching = true
    },
    'forgot-password.success': state => {
        state.fetching = false
    },
    'forgot-password.failure': state => {
        state.fetching = false
    }
}
