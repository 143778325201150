import { propEq, reject } from 'ramda'

/**
 * Delete user mutation.
 *
 * @version 1.0.0
 */
export const deleteUserMutation = {
    'delete.request': state => {
        state.fetching = true
    },
    'delete.success': (state, userId) => {
        state.data = reject(propEq('id', userId), state.data)
        state.fetching = false
    },
    'delete.failure': state => {
        state.fetching = false
    }
}
