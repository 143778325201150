import { find, propEq } from 'ramda'

/**
 * Select course by ID.
 *
 * @version 1.0.0
 *
 * @param {Object} state - The module state.
 * @param {Object} getters - The module getters.
 * @return {Object}
 */
export const selectCourse = (state, getters) => courseId => find(propEq('id', courseId), getters.selectCourses)
