import router from '../../../router'
import { api } from '../../api'
import { toast } from '@/util/toast'
import { i18n } from '@/locale'

/**
 * Activate user action.
 *
 * @author Róbert Zentko
 * @version 1.0.0
 *
 * @param {function} commit
 * @param {Object} payload
 * @param {Object} params
 * @return {Promise}
 */
export const resetPassword = ({ commit }, { payload, params }) => {
    
    // dispatch request
    commit('reset-password.request')
    
    // api request
    return api.auth.resetPassword(payload, params)
        .then(() => {
            
            // dispatch success
            commit('reset-password.success')
            
            // show toast
            toast.success(i18n.t('Heslo nastavené'))
            
            // redirect to dashboard
            router.push({ name: 'login' }).then()
            
        })
        .catch(response => {
            
            // dispatch error
            commit('reset-password.failure')
            
            // show toast
            toast.danger(i18n.t('Heslo sa nepodarilo nastaviť'), response?.response?.data?.message)
            
        })
    
}
