import { prop, sortBy } from 'ramda'

/**
 * Store course mutation.
 *
 * @version 1.0.0
 */
export const storeCourseMutation = {
    'store.request': state => {
        state.fetching = true
    },
    'store.success': (state, payload) => {
        state.data = sortBy(prop('title'), [...state.data, payload])
        state.fetching = false
    },
    'store.failure': state => {
        state.fetching = false
    },
}
