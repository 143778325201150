/**
 * User routes.
 */
export const userRoutes = [
    {
        path: '/users',
        name: 'users.index',
        component: () => import('../../screen/UserList'),
        meta: {
            admin: true,
            title: 'Používatelia'
        }
    },
    {
        path: '/users/create',
        name: 'users.create',
        component: () => import('../../screen/CreateUser'),
        meta: {
            admin: true,
            title: 'Vytvoriť používateľa'
        }
    },
    {
        path: '/users/update/:userId',
        name: 'users.update',
        component: () => import('../../screen/EditUser'),
        meta: {
            admin: true,
            title: 'Upraviť používateľa'
        }
    }
]