import { getUploadProgress } from './getter/getUploadProgress'
import { setUploadProgress } from './mutation/setUploadProgress'

/**
 * Initial state.
 *
 * @type {{progress: number}}
 */
const initialState = {
    progress: 0
}

/**
 * Video module.
 *
 * @author Róbert Zentko
 * @version 1.0.0
 */
export const axiosModule = {
    namespaced: true,
    state: () => initialState,
    mutations: {
        ...setUploadProgress,
        reinitialize: state => Object.assign(state, initialState)
    },
    getters: {
        getUploadProgress
    }
}
