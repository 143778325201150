import { api } from '../../api'
import { toast } from '@/util/toast'
import router from '../../../router'
import { getValidationErrors } from '@/util/getValidationErrors'
import { getErrorMessage } from '@/util/getErrorMessage'

/**
 * Update user action.
 *
 * @version 1.0.0
 *
 * @param {function} commit
 * @param {string} userId
 * @param {Object} payload
 * @return {Promise}
 */
export const updateUser = ({ commit }, { userId, payload }) => {
    
    // hide toasts
    toast.clear()
    
    // dispatch request
    commit('update.request')
    
    // api request
    return api.user.update(userId, payload)
        .then(response => {
            
            // dispatch success
            commit('update.success', response?.data?.data)
            
            // show toast
            toast.success('Používateľ aktualizovaný')
            
            // redirect
            router.push({ name: 'users.index' }).then()
            
        })
        .catch(response => {
            
            // dispatch error
            commit('update.failure')
            
            // show toast
            toast.danger(getErrorMessage(response), getValidationErrors(response))
            
        })
    
}
