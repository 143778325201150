import { filter, propEq } from 'ramda'

/**
 * Select courses by locale.
 *
 * @version 1.0.0
 *
 * @param {Object} state - The module state.
 * @param {Object} getters - The module getters.
 * @return {Object}
 */
export const selectCoursesByLanguage = (state, getters) => language => filter(propEq('language', language), getters.selectCourses)
