import { api } from '../../api'
import { toast } from '@/util/toast'

/**
 * Fetch video link action.
 *
 * @version 1.0.0
 *
 * @param {function} commit
 * @param {string} courseId
 * @param {string} videoId
 * @return {Promise}
 */
export const fetchVideoLink = ({ commit }, { courseId, videoId }) => {
    
    // hide toasts
    toast.clear()
    
    // dispatch login request
    commit('fetch.request')
    
    // api request
    return api.course.play(courseId, videoId)
        .then(response => {
            
            // dispatch success
            commit('fetch.success', response?.data?.data)
            
        })
        .catch(() => {
            
            // dispatch error
            commit('fetch.failure')
            
            // show toast
            toast.danger('Video sa nepodarilo stiahnuť')
            
        })
    
}
