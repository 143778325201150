/**
 * Select course packages by ID.
 *
 * @version 1.0.0
 *
 * @param {Object} state - The module state.
 * @param {Object} getters - The module getters.
 * @return {Object}
 */
export const selectCoursePackages = (state, getters) => courseId => getters.selectCourse(courseId)?.packages
