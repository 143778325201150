import { omit } from 'ramda'
import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
import { authModule } from './auth'
import { courseModule } from './course'
import { userModule } from './user'
import { videoModule } from './video'
import { axiosModule } from './axios'

Vue.use(Vuex)

export const store = new Vuex.Store({
    modules: {
        auth: authModule,
        axios: axiosModule,
        course: courseModule,
        user: userModule,
        video: videoModule
    },
    plugins: [
        new VuexPersistence({
            key: process?.env?.VUE_APP_TITLE || '',
            reducer: state => ({
                auth: omit(['error', 'fetching'], { ...state?.auth }),
                axios: {},
                course: {},
                user: {},
                video: {}
            })
        }).plugin
    ],
    actions: {
        clearAll: ({ commit }) => {
            commit('auth/reinitialize')
            commit('axios/reinitialize')
            commit('course/reinitialize')
            commit('user/reinitialize')
            commit('video/reinitialize')
        }
    }
})
