/**
 * Get error message.
 *
 * @author Róbert Zentko
 * @version 1.0.0
 *
 * @param {Object} response - The axios response.
 * @return {string}
 */
export const getErrorMessage = response => response?.response?.data?.message || ''
