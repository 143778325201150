import Axios from 'axios'
import { store } from './index'
import { i18n } from '@/locale'

// create axios instance
const axios = Axios.create({
    baseURL: process?.env?.VUE_APP_API_URL,
    headers: {
        Accept: 'application/json',
        'Accept-Language': 'sk',
    },
    onUploadProgress: progressEvent => {
        store.commit('axios/setUploadProgress', Math.round((progressEvent?.loaded * 100) / progressEvent?.total))
    },
    timeout: process?.env?.VUE_APP_API_TIMEOUT,
})

// request interceptor
axios.interceptors.request.use(config => {
    
    // set bearer token
    config.headers.Authorization = `Bearer ${store.getters['auth/selectAccessToken']}`
    
    // set language header
    config.headers['Accept-Language'] = i18n.locale
    
    // return config
    return config
    
}, error => Promise.reject(error))

// response interceptor
axios.interceptors.response.use(response => response, async error => {
    const originalRequest = error.config
    const type = error?.response?.data?.type
    
    // Refresh token if it's expired and the request hasn't been retried yet
    if (type === 'JWT_EXPIRED_TOKEN' && ! originalRequest._retry) {
        originalRequest._retry = true // Mark request as retried
        
        try {
            // Fetch new token
            const tokenResponse = await api.auth.refresh()
            const newToken = tokenResponse?.data?.data?.access_token
            
            if (newToken) {
                // Replace old token in request headers
                originalRequest.headers.Authorization = 'Bearer ' + newToken
                
                // Store new token
                store.commit('auth/storeNewToken', newToken)
                
                // Retry the original request with the new token
                return axios(originalRequest)
            }
        } catch (refreshError) {
            // Handle error during token refresh (optional)
            console.error('Token refresh failed:', refreshError)
        }
    }
    
    // Logout if token is invalid, blacklisted, or not found
    if (['JWT_INVALID_TOKEN', 'JWT_BLACKLISTED_TOKEN', 'JWT_TOKEN_NOT_FOUND'].includes(type)) {
        store.dispatch('auth/logout')
    }
    
    // Reject the promise with the original error
    return Promise.reject(error)
})

// api routes
export const api = {
    auth: {
        getUser: () => axios.get('/auth/user'),
        login: payload => axios.post('/auth/login', payload),
        refresh: () => axios.post('/auth/refresh'),
        logout: () => axios.delete('/auth/logout'),
        forgotPassword: payload => axios.post('/auth/forgot-password', payload),
        resetPassword: (payload, params) => axios.post('/auth/reset-password', payload, { headers: { Authorization: null }, params }),
    },
    course: {
        list: () => axios.get('/course'),
        store: payload => axios.post('/course/store', payload, { headers: { 'Content-Type': 'multipart/form-data' }, timeout: 0 }),
        update: (courseId, payload) => axios.post(`/course/update/${courseId}`, payload, { headers: { 'Content-Type': 'multipart/form-data' }, timeout: 0 }),
        delete: courseId => axios.delete(`/course/delete/${courseId}`),
        videos: courseId => axios.get(`/course/${courseId}/videos`),
        play: (courseId, videoId) => axios.get(`/course/${courseId}/play/${videoId}`),
    },
    user: {
        list: (params, signal) => axios.get('/user', { params, signal }),
        get: id => axios.get(`/user/${id}`),
        store: payload => axios.post('/user/store', payload),
        update: (userId, payload) => axios.patch(`/user/update/${userId}`, payload),
        delete: userId => axios.delete(`/user/delete/${userId}`),
        export: () => axios.get('/user/export', { responseType: 'blob', timeout: 30 * 1000 }),
    },
}
