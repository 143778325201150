/**
 * Set upload progress.
 *
 * @author Róbert Zentko
 * @version 1.0.0
 */
export const setUploadProgress = {
    'setUploadProgress': (state, payload) => {
        state.progress = parseInt(payload)
    }
}
