import { resetPassword } from './action/resetPassword'
import { login } from './action/login'
import { isAuthenticated } from './getter/isAuthenticated'
import { isFetching } from './getter/isFetching'
import { selectAccessToken } from './getter/selectAccessToken'
import { selectRole } from './getter/selectRole'
import { selectUser } from './getter/selectUser'
import { resetPasswordMutation } from './mutation/resetPassword'
import { loginMutation } from './mutation/login'
import { isAdmin } from './getter/isAdmin'
import { logout } from './action/logout'
import { storeNewToken } from './mutation/storeToken'
import { selectUserId } from './getter/selectUserId'
import { forgotPassword } from './action/forgotPassword'
import { forgotPasswordMutation } from './mutation/forgotPassword'
import { getUserMutation } from '@/store/auth/mutation/getUser'
import { getUser } from '@/store/auth/action/getUser'
import { selectUserStatus } from '@/store/auth/getter/selectUserStatus'

/**
 * Initial state.
 */
const initialState = {
    accessToken: null,
    fetching: false,
    user: {},
}

/**
 * Auth module.
 *
 * @author Róbert Zentko
 * @version 1.0.0
 */
export const authModule = {
    namespaced: true,
    state: () => initialState,
    mutations: {
        ...forgotPasswordMutation,
        ...getUserMutation,
        ...loginMutation,
        ...resetPasswordMutation,
        ...storeNewToken,
        reinitialize: state => Object.assign(state, initialState),
    },
    actions: {
        getUser,
        login,
        logout,
        forgotPassword,
        resetPassword,
    },
    getters: {
        isAdmin,
        isAuthenticated,
        isFetching,
        selectAccessToken,
        selectRole,
        selectUser,
        selectUserId,
        selectUserStatus,
    },
}
