import Vue from 'vue'
import App from './App.vue'
import VueToast from 'vue-toast-notification'
import './resource/scss/base.scss'
import 'vue-toast-notification/src/themes/sugar/index.scss'
import router from './router'
import { store } from './store'
import { i18n } from '@/locale'

Vue.config.productionTip = false

Vue.use(VueToast)

export const EventBus = new Vue({
    i18n,
    render: h => h(App),
    router,
    store,
}).$mount('#app')
