import { fetchUsers } from './action/fetchUsers'
import { fetchUsersMutation } from './mutation/fetchUsers'
import { isFetching } from './getter/isFetching'
import { selectUsers } from './getter/selectUsers'
import { deleteUserMutation } from './mutation/deleteUser'
import { deleteUser } from './action/deleteUser'
import { storeUser } from './action/storeUser'
import { storeUserMutation } from './mutation/storeUser'
import { selectUser } from './getter/selectUser'
import { updateUser } from './action/updateUser'
import { updateUserMutation } from './mutation/updateUser'
import { selectUserCourses } from './getter/selectUserCourses'
import { selectPagination } from '@/store/user/getter/selectPagination'

/**
 * Initial state.
 *
 * @type {{data: *[], fetching: boolean}}
 */
const initialState = {
    data: [],
    fetching: false,
    pagination: {
        currentPage: 1,
        lastPage: null,
        perPage: 50,
    },
    user: null,
}

/**
 * User module.
 *
 * @author Róbert Zentko
 * @version 1.0.0
 */
export const userModule = {
    namespaced: true,
    state: () => initialState,
    mutations: {
        ...fetchUsersMutation,
        ...storeUserMutation,
        ...updateUserMutation,
        ...deleteUserMutation,
        reinitialize: state => Object.assign(state, initialState)
    },
    actions: {
        fetchUsers,
        storeUser,
        updateUser,
        deleteUser
    },
    getters: {
        isFetching,
        selectPagination,
        selectUsers,
        selectUser,
        selectUserCourses
    }
}
