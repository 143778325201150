import { flatten, isNil, join, values } from 'ramda'

/**
 * Transform validation errors.
 *
 * @author Róbert Zentko
 * @version 1.0.0
 *
 * @param {Object} response - The axios response.
 * @return {string}
 */
export const getValidationErrors = response => {
    const errors = response?.response?.data?.errors
    
    return isNil(errors) ? '' : join('<br>', flatten(values(errors)))
}
