import { api } from '../../api'
import router from '../../../router'
import { toast } from '@/util/toast'
import { getValidationErrors } from '@/util/getValidationErrors'
import { getErrorMessage } from '@/util/getErrorMessage'

/**
 * Store user action.
 *
 * @version 1.0.0
 *
 * @param {function} commit
 * @param {Object} payload
 * @return {Promise}
 */
export const storeUser = ({ commit }, payload) => {
    
    // hide toasts
    toast.clear()
    
    // dispatch request
    commit('store.request')
    
    // api request
    return api.user.store(payload)
        .then(response => {
            
            // dispatch success
            commit('store.success', response?.data?.data)
            
            // show toast
            toast.success('Používateľ vytvorený')
            
            // redirect
            router.push({ name: 'users.index' }).then()
            
        })
        .catch(response => {
            
            // dispatch error
            commit('store.failure')
            
            // show toast
            toast.danger(getErrorMessage(response), getValidationErrors(response))
            
        })
    
}
