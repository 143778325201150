/**
 * Whether the user is authenticated.
 *
 * @author Róbert Zentko
 * @version 1.0.0
 *
 * @param {Object} state - The module state.
 * @param {Object} getters - The module getters.
 * @return {boolean}
 */
export const isAuthenticated = (state, getters) => !! getters.selectAccessToken
