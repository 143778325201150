import { fetchVideoLink } from './action/fetchVideoLink'
import { fetchVideoLinkMutation } from './mutation/fetchVideoLink'
import { isFetching } from './getter/isFetching'
import { selectVideoLink } from './getter/selectVideoLink'
import { selectCurrentVideo } from './getter/selectVideoId'
import { setCurrentVideo } from './mutation/setCurrentVideo'

/**
 * Initial state.
 *
 * @type {{fetching: boolean, video: {}}}
 */
const initialState = {
    fetching: false,
    video: {}
}

/**
 * Video module.
 *
 * @author Róbert Zentko
 * @version 1.0.0
 */
export const videoModule = {
    namespaced: true,
    state: () => initialState,
    mutations: {
        ...fetchVideoLinkMutation,
        ...setCurrentVideo,
        reinitialize: state => Object.assign(state, initialState)
    },
    actions: {
        fetchVideoLink
    },
    getters: {
        isFetching,
        selectCurrentVideo,
        selectVideoLink
    }
}
