/**
 * Select the auth user.
 *
 * @author Róbert Zentko
 * @version 1.0.0
 *
 * @param {Object} state - The module state.
 * @return {null|string}
 */
export const selectUserStatus = state => state?.user?.status
