/**
 * Activate user mutation.
 *
 * @author Róbert Zentko
 * @version 1.0.0
 */
export const storeNewToken = {
    'storeNewToken': (state, token) => {
        state.accessToken = token
    }
}
