import { api } from '../../api'
import { toast } from '@/util/toast'

/**
 * Set course expiration action.
 *
 * @version 1.0.0
 *
 * @param {function} commit
 * @param {string} courseId
 * @param {string} packageId
 * @return {Promise}
 */
export const setCourseExpiration = ({ commit }, { courseId, packageId }) => {
    
    // dispatch request
    commit('expiration.request')
    
    // api request
    return api.course.setExpiration(courseId, packageId)
        .then(response => {
            
            // dispatch success
            commit('expiration.success', {
                courseId,
                expire_at: response?.data?.data?.expire_at,
                packageId
            })
            
        })
        .catch(() => {
            
            // dispatch error
            commit('expiration.failure')
            
            // show toast
            toast.danger('Kurz', 'Nepodarilo sa nastaviť expiráciu kurzu')
            
        })
    
}
