import { map } from 'ramda'

/**
 * Set course expiration mutation.
 *
 * @version 1.0.0
 */
export const setCourseExpirationMutation = {
    'expiration.request': state => {
        state.fetching = true
    },
    'expiration.success': (state, { courseId, expire_at }) => {
        state.data = map(item => item.id === courseId ? { ...item, expire_at } : item, state.data)
        state.fetching = false
    },
    'expiration.failure': state => {
        state.fetching = false
    }
}
