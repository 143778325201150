/**
 * Select the auth user.
 *
 * @author Róbert Zentko
 * @version 1.0.0
 *
 * @param {Object} state - The module state.
 * @param {Object} getters - The module getters.
 * @return {null|string}
 */
export const selectUserId = (state, getters) => getters.selectUser?.id
