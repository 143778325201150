import { isFetching } from './getter/isFetching'
import { selectCourses } from './getter/selectCourses'
import { selectCourse } from './getter/selectCourse'
import { deleteCourse } from './action/deleteCourse'
import { fetchCourses } from './action/fetchCourses'
import { storeCourse } from './action/storeCourse'
import { fetchCoursesMutation } from './mutation/fetchCourses'
import { storeCourseMutation } from './mutation/storeCourse'
import { deleteCourseMutation } from './mutation/deleteCourse'
import { setCourseExpiration } from './action/setCourseExpiration'
import { setCourseExpirationMutation } from './mutation/setCourseExpiration'
import { updateCourseMutation } from './mutation/updateCourse'
import { updateCourse } from './action/updateCourse'
import { selectCoursePackages } from './getter/selectCoursePackages'
import { selectCoursePackagesId } from './getter/selectCoursePackagesId'
import { selectCoursesByLanguage } from '@/store/course/getter/selectCoursesByLanguage'

/**
 * Initial state.
 *
 * @type {{data: *[], fetching: boolean}}
 */
const initialState = {
    data: [],
    fetching: false,
}

/**
 *
 * Course module.
 *
 * @author Róbert Zentko
 * @version 1.0.0
 */
export const courseModule = {
    namespaced: true,
    state: () => initialState,
    mutations: {
        ...fetchCoursesMutation,
        ...storeCourseMutation,
        ...deleteCourseMutation,
        ...setCourseExpirationMutation,
        ...updateCourseMutation,
        reinitialize: state => Object.assign(state, initialState),
    },
    actions: {
        fetchCourses,
        storeCourse,
        updateCourse,
        deleteCourse,
        setCourseExpiration,
    },
    getters: {
        isFetching,
        selectCourse,
        selectCourses,
        selectCoursesByLanguage,
        selectCoursePackages,
        selectCoursePackagesId,
    },
}
