/**
 * Fetch video link mutation.
 *
 * @author Róbert Zentko
 * @version 1.0.0
 */
export const fetchVideoLinkMutation = {
    'fetch.request': state => {
        state.fetching = true
    },
    'fetch.success': (state, payload) => {
        state.fetching = false
        state.link = payload?.link
        state.video = payload?.video
    },
    'fetch.failure': state => {
        state.fetching = false
        state.link = null
        state.video = null
    }
}
